// assets
import {
  FileOutlined,
  DashboardOutlined,
  ShoppingOutlined,
  BranchesOutlined,
  VideoCameraOutlined,
  UnlockOutlined,
  CompassOutlined,
  UsergroupAddOutlined,
  UserAddOutlined,
  UserSwitchOutlined,
  UserOutlined,
  FileTextOutlined
} from "@ant-design/icons";

const icons = {
  FileOutlined,
  DashboardOutlined,
  ShoppingOutlined,
  BranchesOutlined,
  VideoCameraOutlined,
  UnlockOutlined,
  CompassOutlined,
  UsergroupAddOutlined,
  UserAddOutlined,
  UserSwitchOutlined,
  UserOutlined,
  FileTextOutlined
};

const operationDashboard = {
  id: "operation-dashboard",
  title: "Genel",
  type: "item",
  items: [
    {
      id: "operation-dashboard",
      title: "Ön İzleme",
      type: "item",
      url: "/operation-dashboard",
      icon: <icons.DashboardOutlined />,
    },
  ],
};

const management = {
  id: "management",
  title: "Yönetim",
  type: "item",
  items: [
    {
      id: "operation-order-management",
      title: "Sipariş",
      type: "item",
      url: "/operation-order-management",
      icon: <icons.ShoppingOutlined />,
    },
  ],
};

const planner = {
  id: "planner-operation",
  title: "Planlama",
  type: "group",
  icon: <icons.BranchesOutlined />,
  items: [
    {
      id: "planner",
      title: "Rota Planlama",
      type: "item",
      url: "/operation-planner",
    },
    {
      id: "plannerRoutes",
      title: "Rota Listesi",
      type: "item",
      url: "/operation-planner-routes",
    },
  ],
};

const tracking = {
  id: "tracking",
  title: "Canlı Takip",
  type: "item",
  items: [
    {
      id: "liveTracking",
      title: "Canlı Takip",
      type: "item",
      url: "/operation-live-tracking",
      icon: <icons.VideoCameraOutlined />,
    },
  ],
};

const debit = {
  id: "debit",
  title: "Kurye Zimmet",
  type: "item",
  items: [
    {
      id: "courier-debit-management",
      title: "Kurye Zimmet",
      type: "item",
      url: "/operation-courier-debit",
      icon: <icons.UnlockOutlined />,
    },
  ],
};

const coordinate = {
  id: "coordinate",
  title: "Koordinatlama",
  type: "item",
  items: [
    {
      id: "coordinate-management",
      title: "Koordinatlama",
      type: "item",
      url: "/operation-coordinate",
      icon: <icons.CompassOutlined />,
    },
  ],
};

const userManagement = {
  id: 'operation-user',
  title: 'Kullanıcılar',
  type: 'group',
  icon: <icons.UserSwitchOutlined />,
  items: [
    {
      id: 'operation-courier-management',
      title: 'Kurye',
      type: 'item',
      url: '/operation-courier-management',
      icon: <icons.UserOutlined />
    },
    {
      id: 'operation-vehicle-management',
      title: 'Araç',
      type: 'item',
      url: '/operation-vehicle-management',
      icon: <icons.UserOutlined />
    },
    {
      id: 'operation-subcontractor',
      title: 'Taşeron',
      type: 'item',
      url: '/operation-subcontractor',
      icon: <icons.UserOutlined />
    },
  ]
};

const userApproveManagement = {
  id: "operation-user-approve",
  title: "Kullanıcı Onayı",
  type: "group",
  icon: <icons.UsergroupAddOutlined />,
  items: [
    {
      id: "operation-courier-approve",
      title: "Kurye Onay",
      type: "item",
      url: "/operation-courier-approve",
      icon: <icons.UserAddOutlined />,
    },
    {
      id: "operations-vehicle-approve",
      title: "Araç/Taşeron Onay",
      type: "item",
      url: "/operation-vehicle-approve",
      icon: <icons.UserAddOutlined />,
    },
  ],
};

const operationReport = {
  id: "operation-report",
  title: "Raporlar",
  type: "group",
  icon: <icons.FileOutlined />,
  items: [
    {
      id: "debit-report",
      title: "Zimmet Raporu",
      type: "item",
      url: "/operation-debit-report",
    },
    {
      id: "order-report",
      title: "Sipariş Raporu",
      type: "item",
      url: "/operation-order-report",
    },
    {
      id: "route-report",
      title: "Rota Raporu",
      type: "item",
      url: "/operation-route-report",
    },
    {
      id: "distributor-report",
      title: "Dağıtıcı Mesai Raporu",
      type: "item",
      url: "/operation-distributor-report",
    },
    {
      id: "performance-report",
      title: "Performans Raporu",
      type: "item",
      url: "/operation-performance-report",
    },
  ],
};

export const operation = {
  operationDashboard,
  management,
  planner,
  debit,
  tracking,
  coordinate,
  userManagement,
  userApproveManagement,
  operationReport,
};
