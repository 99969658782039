import axios from "../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const deleteEarnings = createAsyncThunk(
  "earning/deleteEarnings",
  async (claimIDs, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `/claim/bills_delete/${claimIDs.join(",")}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
