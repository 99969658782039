import axios from "../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const setDebit = createAsyncThunk(
  "debit/setDebit",
  async ({ projectID, branchID, startDate, endDate }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/debit`,
        {},
        {
          params: {
            projectID,
            branchID,
            startDate,
            endDate,
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
