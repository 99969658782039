import axios from '../../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const  putArchiveUser = createAsyncThunk(
    'putArchiveUser',
    async (userID, { rejectWithValue }) => {
        const response = await axios.put(`/user/archive_user/${userID}`);
        return response.data;
    }
  ); 
