import axios from "../../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getDeletedList = createAsyncThunk(
  "getDeletedList",
  async (_, { rejectWithValue }) => {
    const response = await axios.get(`/user/deleted_user`);
    return response.data;
  }
);
