import { createSlice } from '@reduxjs/toolkit';
import { getSubcontractorDocuments } from './getSubcontractorDocuments.repository';

const initialState = {
  loading: 'idle',
  data: null,
  error: null
};

const getSubcontractorDocumentsSlice = createSlice({
  name: 'getSubcontractorDocuments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSubcontractorDocuments.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(getSubcontractorDocuments.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.data = action.payload;
        state.error = null;
      })
      .addCase(getSubcontractorDocuments.rejected, (state, action) => {
        state.loading = 'idle';
        state.error = action.payload;
      });
  },
});

export default getSubcontractorDocumentsSlice.reducer;