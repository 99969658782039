import axios from "../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getClientProject = createAsyncThunk(
  "project/getClientProject",
  async (clientID, { rejectWithValue }) => {
    const response = await axios.get(`/project/client/${clientID}`);
    return response.data;
  }
);
