import axios from "../../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const deleteAssignedCourier = createAsyncThunk(
  "deleteAssignedCourier",
  async (courierID, { rejectWithValue }) => {
    const response = await axios.delete(`/user/${courierID}`);
    return response.data;
  }
);
