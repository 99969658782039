// assets
import {
  FileOutlined,
  DashboardOutlined,
  ShoppingOutlined,
  BranchesOutlined,
  VideoCameraOutlined,
  UnlockOutlined,
  CompassOutlined,
  UsergroupAddOutlined,
  UserAddOutlined,
  UserSwitchOutlined,
  UserOutlined,
  FileTextOutlined,
} from "@ant-design/icons";

const icons = {
  FileOutlined,
  DashboardOutlined,
  ShoppingOutlined,
  BranchesOutlined,
  VideoCameraOutlined,
  UnlockOutlined,
  CompassOutlined,
  UsergroupAddOutlined,
  UserAddOutlined,
  UserSwitchOutlined,
  UserOutlined,
  FileTextOutlined,
};

const clientDashboard = {
  id: "client-dashboard",
  title: "Genel",
  type: "item",
  items: [
    {
      id: "client-dashboard",
      title: "Ön İzleme",
      type: "item",
      url: "/client-dashboard",
      icon: <icons.DashboardOutlined />,
    },
  ],
};

const clientManagement = {
  id: "management",
  title: "Yönetim",
  type: "item",
  items: [
    {
      id: "client-order-management",
      title: "Sipariş",
      type: "item",
      url: "/client-order-management",
      icon: <icons.ShoppingOutlined />,
    },
  ],
};

const clientReport = {
  id: "client-report",
  title: "Raporlar",
  type: "group",
  icon: <icons.FileOutlined />,
  items: [
    {
      id: "client-report",
      title: "Sipariş Raporu",
      type: "item",
      url: "/client-order-report",
    },
  ],
};

export const client = {
  clientDashboard,
  clientManagement,
  clientReport,
};
