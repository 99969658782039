// RoleSelect.js
import React, { useState } from "react";
import { Radio } from "antd";
import { useDispatch } from "react-redux";
import { roleTranslations } from "../../../../core/utils/constants";
import { getUserProfile } from "../../../../core/store/profile/getUser.repository";
import { setRole } from "../../../../core/store/auth/setRole.repository";

const RoleSelect = ({ userRole, refreshToken, currentRole }) => {
  const [selectedRole, setSelectedRole] = useState(currentRole);
  const dispatch = useDispatch();

  const handleRoleChange = (event) => {
    const selectedRole = event.target.value;
    setSelectedRole(selectedRole);

    if (selectedRole && refreshToken) {
      dispatch(
        setRole({
          refreshToken: refreshToken,
          role: selectedRole === "accountantRole" ? "accountant" : selectedRole,
        })
      );
      dispatch(getUserProfile());
    }
  };

  const filteredRoles = userRole?.filter(
    (role) => role !== "clientManager" && role !== "subcontractor" && role !== "clientOperations"
  );

  return (
    <Radio.Group onChange={handleRoleChange} value={selectedRole}>
      {filteredRoles.map((role) => (
        <Radio
          value={role === "accountantRole" ? "accountant" : role}
          key={role === "accountantRole" ? "accountant" : role}
        >
          {roleTranslations[role] || role}
        </Radio>
      ))}
    </Radio.Group>
  );
};

export default RoleSelect;
