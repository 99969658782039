import axios from "../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const putEarning = createAsyncThunk(
  "earning/putEarning",
  async (updatedData , { rejectWithValue }) => {
    // try {
      const response = await axios.put(`/claim/bill`, updatedData);
      return response.data;
    // } catch (err) {
    //   return rejectWithValue(err.message);
    // }
  }
);
