import axios from "../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const putEarnings = createAsyncThunk(
  "earning/putEarnings",
  async (updatedData, { rejectWithValue }) => {
    const response = await axios.put(`/claim/invoice_update`, updatedData);
    return response.data;
  }
);
