import axios from "../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const setDashboardFilter = createAsyncThunk(
  "dashboard/setDashboardFilter",
  async (
    { projectID, branchID, startDate, endDate, role, clientID },
    { rejectWithValue }
  ) => {
    // try {
    const todayStart = new Date();
    todayStart.setHours(0, 0, 0, 0);
    const defaultStartDate = Math.floor(todayStart.getTime() / 1000);
    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999);
    const defaultEndDate = Math.floor(todayEnd.getTime() / 1000);
    
    const effectiveStartDate =
      startDate === undefined
        ? defaultStartDate.toString()
        : startDate.toString();
    const effectiveEndDate =
      endDate === undefined ? defaultEndDate.toString() : endDate.toString();

    const queryParams = new URLSearchParams({
      projectID: projectID || "*",
      branchID: branchID || "*",
      startDate: effectiveStartDate,
      endDate: effectiveEndDate,
      ...(role === "clientAdmin" && { clientID }),
    }).toString();

    const response = await axios.get(`/dashboard/operation?${queryParams}`);
    return response.data;
    // } catch (err) {
    //   return rejectWithValue(err.message);
    // }
  }
);
