import { createSlice } from '@reduxjs/toolkit';
import { deleteAssignedCourier } from './deleteAssignedCourier.repository';

const initialState = {
  loading: 'idle',
  data: null,
  error: null
};

const deleteAssignedCourierSlice = createSlice({
  name: 'deleteAssignedCourier',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteAssignedCourier.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(deleteAssignedCourier.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.data = action.payload;
        state.error = null;
      })
      .addCase(deleteAssignedCourier.rejected, (state, action) => {
        state.loading = 'idle';
        state.error = action.payload;
      });
  },
});

export default deleteAssignedCourierSlice.reducer;