import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import FormData from 'form-data';

export const uploadFile = createAsyncThunk(
  'storage/uploadFile',
  async ({ file, fileCategory }, { rejectWithValue }) => {
    let data = new FormData();
    data.append('category', fileCategory);
    data.append('file', file);

    const refresh = JSON.parse(sessionStorage.getItem('refresh'));
    // try {
      const response = await axios.post(process.env.REACT_APP_STORAGE_BASE_URL, data, {
        headers: {
          'Authorization': `Bearer ${refresh.data.storageToken}`,
          'access-control-allow-origin': '*',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
  
        },
        maxBodyLength: Infinity,
      });
      return response.data;
    // } catch (err) {
    //   return rejectWithValue(err.message);
    // }
  }
);