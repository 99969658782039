import axios from "../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getInvoiceList = createAsyncThunk(
  "getInvoiceList",
  async (_, { rejectWithValue }) => {
    const response = await axios.get(`/claim/invoice_list`);
    return response.data;
  }
);
