import axios from "../../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const setSubcontractorAndVehicle = createAsyncThunk(
  "user/setSubcontractorAndVehicle",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await axios.post("/user/create-subcontractor-and-vehicle", {
        subcontractors: [requestData],
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
