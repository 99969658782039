import axios from "../../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getArchivedList = createAsyncThunk(
  "getArchivedList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/user/archived_user`, {
        params: {
          roles: [
            "clientOperationsRole",
            "clientManagerRole",
            "courierRole",
            "vehicleRole",
            "subcontractorRole",
          ],
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
